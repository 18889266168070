import React, {useEffect} from 'react'
import { graphql } from "gatsby"
import Seo from "../components/Seo"

import Splitting from "splitting";
import ScrollOut from "scroll-out";

import JournalList from '../components/JournalList'
import H1 from "../components/H1"

// markup
const JournalPage = ({data}) => {
  const {
    allWpJournal: { nodes },
    wpPage: { title, content, seo },
  } = data

  useEffect(() => {
    // do something
    Splitting();
    const scroll = ScrollOut({once:true});
  
    setTimeout(() => {
      scroll.update();
    },500);

    return () => {
      scroll.teardown();
    }
  }, []);

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <H1 title={title} />
      <div className="mt-10 mt-md-20 mb-20 mb-md-40 text-center col-md-10  mx-auto" data-scroll data-splitting="" dangerouslySetInnerHTML={{ __html: content }} />

      <div className="container-fluid py-20_ bg-light-gray_">
 
          <JournalList posts={nodes} />
 
      </div>

    </>
  )
}


export const query = graphql`
{
  wpPage(databaseId: {eq: 2498}) {
    title
    content
    seo {
      title
      metaDesc
    }
  }
  allWpJournal(sort: {fields: [menuOrder], order: ASC}) {
    nodes {
      id
      title
      content
      featuredImage {
        node {
          localFile {
        		childImageSharp {
							gatsbyImageData(
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      ACFjournal {
        datum
        link
        imgBrowser
      }
    }
  }
}
`

export default JournalPage
