import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";



function SingleJournal({ post }) {

  const image = getImage(post.featuredImage.node.localFile);
  return (
    <>
      <div key={post.id} className="row mb-5 pt-5">
        <div className="col-md-6 d-flex justify-content-end">
          <GatsbyImage image={image} alt={post.title} className={ post.ACFjournal.imgBrowser ? ' img-browser' : '' } />
        </div>

        <div className="col-md-6 my-3 d-flex flex-column justify-content-center"  >

          <div className="small">{post.ACFjournal.datum}</div>
          <h2 className="mb-3" data-scroll data-splitting="">
            {post.title}
          </h2>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
    
          <a href={post.ACFjournal.link} className="link-detail small me-auto" target="_blank" rel="noopener noreferrer">
            Anschauen
          </a>
      </div>
      </div>
  
    </>
  )


}

export default function JournalList({ posts}) {

  console.log(posts)

  return (
    <>
    {posts.map((post) => (
     <SingleJournal key={post.id} post={post} />
    ))}
    </>
  )
}